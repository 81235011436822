import React from 'react'
import './About.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import aboutImg from './about.jpg'

function About() {
  return (
    <section id="about" className="About">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="TwoSideContainer">
              <div className="AboutText">
                <SectionTitle title="Диантус - доставка цветов в Мурманске" />
                <p>
                  Диантус – доставка цветов в Мурманске. В г. Мурманске компания
                  была основана с 2002г. Широкий ассортимент и отличное качество
                  срезанных и горшечных растений. Мы привозим цветы каждую
                  неделю, поэтому они всегда свежие. Есть собственное
                  производство - Тепличное хозяйство «АТЭНА». <br />
                  <br />
                  Для вас работают профессиональные флористы. Все букеты и
                  композиции составлены ими и являются авторской работой.
                  <br />
                  <br />
                  Будьте уверены, что вам гарантирована быстрая доставка цветов,
                  букетов и подарков на любой вкус.
                </p>
              </div>
              <div
                className="AboutImg"
                style={{ backgroundImage: `url(${aboutImg})` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
