import React, { useEffect, useState, useContext } from 'react'
import { withRouter } from 'react-router'
import parse from 'html-react-parser'
import { find, isEmpty, isNull, isObject } from 'lodash'
import Carousel from '../../ui/Carousel/Carousel'
import Hero from '../Hero/Hero'
import ContactUs from '../ContactUs/ContactUs'
import Faq from '../Faq/Faq'
import Feeds from '../Feeds/Feeds'
import TourConstructor from '../TourConstructor/TourConstructor'
import { AuthContext } from '../../App'
import './TourPage.sass'
import InfoLine from '../../ui/InfoLine/InfoLine'
import Button from '../../ui/Button/Button'
import { tours } from '../TourCatalog/tours'
import Img from '../../ui/Img/Img'
import useWindowSize from '../../hooks/useWindowSize'
import location from '../../locale/location'
import getDoc from '../../utils/db/getDoc'
import Spinner from '../../ui/Spinner/Spinner'
import LegacyInfo from '../LegacyInfo/LegacyInfo'
import Icon from '../../ui/Icon/Icon'
import Seo from '../../ui/Seo/Seo'

function TourPage({ ...router }) {
  const tourId = router.match.params.id
  const [tourData, setTourData] = useState(null)
  console.log('🚀 ~ TourPage ~ tourData:', tourData)
  const { lang, setShowContactUs } = useContext(AuthContext)

  useEffect(() => {
    if (tourId) {
      getDoc({
        path: 'tours',
        docId: tourId,
        docIdName: 'tourId',
      }).then((data) => {
        const photoString = data.ru.photoLinks
        const array = photoString.split('\n')
        data.ru.photoLinks = array
        setTourData(data)
      })
    }
  }, [tourId])

  return isNull(tourData) ? (
    <Spinner type="module" />
  ) : (
    <>
      <Seo
        title={tourData[lang].meta_title}
        description={tourData[lang].meta_desc}
        keywords={tourData[lang].meta_keywords}
      />
      <Hero
        // background={find(tourData.photos, ['isAvatar', true]).publicUrl}
        background={tourData.ru.photoLinks[0]}
        title={tourData[lang].title}
        detailTourButton
        detailLink={`/tours/${tourData.tourId}#tour`}
      />
      <section id="tour" className="TourPage DefaultSection">
        {tourData && (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ol
                  vocab="https://schema.org/"
                  typeof="BreadcrumbList"
                  className="BreadcrumbList"
                >
                  <li property="itemListElement" typeof="ListItem">
                    <a
                      property="item"
                      typeof="WebPage"
                      href="https://iterrainc.ru"
                    >
                      <span property="name">Главная</span>
                    </a>
                    <meta property="position" content="1" />
                  </li>
                  <li>
                    <Icon name="long-arrow-right" />
                  </li>
                  <li property="itemListElement" typeof="ListItem">
                    <a
                      property="item"
                      typeof="WebPage"
                      href={`https://iterrainc.ru/tours/${tourData.tourId}`}
                    >
                      <span property="name">{tourData[lang].title}</span>
                    </a>
                    <meta property="position" content="2" />
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="TourPage-Content">
                  <div className="Tour-Slider">
                    <Carousel
                      element="TourCarousel"
                      options={{
                        type: 'carousel',
                        perView: 1,
                        autoplay: 5000,
                        useDots: true,
                        useArrows: true,
                      }}
                    >
                      {tourData.ru.photoLinks.map((src) => (
                        <img key={src} src={src} />
                      ))}

                      {/* {tourData.photos
                        .filter((p) => p.isAvatar !== true)
                        .slice(1)
                        .map((src) => (
                          <img key={src.publicUrl} src={src.publicUrl} />
                        ))} */}
                    </Carousel>
                  </div>

                  <div className="Tour-Data">
                    <div className="Title">{tourData[lang].title}</div>
                    <div className="Description">
                      {parse(tourData[lang].description)}
                    </div>
                    {tourData[lang].season && (
                      <InfoLine
                        icon="calendar-alt"
                        title={location[lang].tourCatalog.season}
                        content={tourData[lang].season}
                      />
                    )}
                    {tourData[lang].duration && (
                      <InfoLine
                        icon="clock"
                        title={location[lang].tourCatalog.duration.title}
                        content={`${tourData[lang].duration}`}
                      />
                    )}
                    {location[lang].tourCatalog.price && (
                      <InfoLine
                        icon="usd-circle"
                        title={location[lang].tourCatalog.price}
                        content={
                          <div>
                            {tourData[lang].price.map((p) => (
                              <p>
                                {p.value} {p.desc}
                              </p>
                            ))}
                          </div>
                        }
                      />
                    )}

                    <div className="Buttons">
                      <Button
                        theme="solid"
                        title={location[lang].tourCatalog.bookTourButton}
                        fill="black"
                        onClick={() =>
                          setShowContactUs({
                            show: true,
                            title: 'Забронировать тур',
                            emailSubject: `Заявка на бронь тура ${tourData[lang].title}`,
                            target: 'bron_tur',
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="TourPage-Program">
                  <div className="Program">
                    <p className="Title">
                      {location[lang].tourCatalog.program}
                    </p>
                    <div className="Text">{parse(tourData[lang].program)}</div>
                  </div>
                  <div className="Tour-Img">
                    <Img
                      src={
                        tourData.ru.photoLinks.length > 1
                          ? tourData.ru.photoLinks[1]
                          : tourData.ru.photoLinks[0]
                      }
                    />
                    {/* <Img
                      src={
                        tourData.photos.length > 1
                          ? tourData.photos.filter(
                              (p) => p.isAvatar !== true
                            )[0].publicUrl
                          : tourData.photos[0].publicUrl
                      }
                    /> */}
                  </div>
                </div>
                <div className="TourPage-OtherDetails">
                  {!isEmpty(tourData[lang].include) && (
                    <div className="Included">
                      <p className="Title">
                        {location[lang].tourCatalog.included}
                      </p>
                      <div className="Text">
                        {parse(tourData[lang].include)}
                      </div>
                    </div>
                  )}
                  {!isEmpty(tourData[lang].prepare) && (
                    <div className="HowToPrepare">
                      <p className="Title">
                        {location[lang].tourCatalog.howToPrepare}
                      </p>
                      <div className="Text">
                        {parse(tourData[lang].prepare)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <TourConstructor />
      <Feeds />
      <Faq />
      <ContactUs />
      <LegacyInfo />
    </>
  )
}

export default withRouter(TourPage)
