import React, { useEffect, createContext, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './pages/Home/Home'
import SitePage from './pages/SitePage/SitePage'
import { db, firebase } from './config/firebase'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import CookieNotification from './components/CookieNotification/CookieNotification'
import TourPage from './components/TourPage/TourPage'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import getDoc from './utils/db/getDoc'
import { isEmpty } from 'lodash'
import NotFound from './ui/NotFound/NotFound'
import SiteMap from './components/SiteMap/SiteMap'
import Products from './components/Products/Products'
import Delivery from './pages/Delivery/Delivery'
import Cart from './components/Cart/Cart'
import addDoc from './utils/db/addDoc'
import Checkout from './components/Checkout/Checkout'
import Success from './components/Success/Success'

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [showContactUs, setShowContactUs] = useState({
    show: false,
    title: '',
    emailSubject: '',
  })
  const [lang, setLang] = useState('ru')
  const [cart, setCart] = useState({ id: null, products: [] })
  console.log('🚀 ~ AuthProvider ~ cart:', cart)
  // const [settings, setSettings] = useState(null)

  // useEffect(() => {
  //   getDoc({ path: 'settings', docId: '0tOG33b4DzEfG8BS9x7x' }).then((data) =>
  //     setSettings(data)
  //   )
  // }, [])

  useEffect(() => {
    // const userStateListener = firebase.auth().onAuthStateChanged(setUser)
    let dbCartListener = () => null

    if (localStorage.getItem('cart')) {
      getDoc({ path: 'carts', docId: localStorage.getItem('cart') }).then(
        (gCartData) => {
          if (!isEmpty(gCartData)) {
            dbCartListener = db
              .collection('carts')
              .doc(localStorage.getItem('cart'))
              .onSnapshot((snap) => {
                const uCart = {
                  id: snap.id || null,
                  // products: [],
                  ...snap.data(),
                }
                setCart(uCart)
              })
          }
        }
      )
    } else {
      addDoc({
        path: 'carts',
        data: { products: [], cartType: 'guest' },
      }).then((docId) => {
        localStorage.setItem('cart', docId)
        dbCartListener = db
          .collection('carts')
          .doc(docId)
          .onSnapshot((snap) => {
            const uCart = {
              id: docId,
              products: [],
              ...snap.data(),
            }
            setCart(uCart)
          })
      })
    }

    return () => {
      //  userStateListener()
      //  dbUserListener()
      //  setUser(null)
      setCart({ id: null, products: [] })
      dbCartListener()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{ user, cart, showContactUs, setShowContactUs, lang, setLang }}
    >
      {/* {!isEmpty(settings) && children} */}
      {children}
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <SitePage>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/products/:id" exact component={Products} />
          <Route path="/delivery" exact component={Delivery} />
          <Route path="/cart" exact component={Cart} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/success" exact component={Success} />

          {/* <Route path="/tours/:id" exact component={TourPage} /> */}
          {/* <Route path="/project/:id" exact component={ProjectPage} />
        <Route
          path={['/manage-project', '/manage-project/:id']}
          exact
          component={ManageProjectPage}
        />
        <Route path="/admin" exact component={Auth} /> */}
          {/* <Route path="/privacy-policy" exact component={PrivacyPolicy} /> */}
          {/* <Route path="/sitemap" exact component={SiteMap} /> */}
          {/* <Route path="" component={NotFound} /> */}
        </Switch>
      </SitePage>
      <CookieNotification />
    </AuthProvider>
  )
}

export default App
