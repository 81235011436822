import React from 'react'
import './CheckTag.sass'
import Icon from '../../ui/Icon/Icon'

function CheckTag({ isActive = false }) {
  return (
    <span className={`CheckTag ${isActive && 'CheckTag_theme_active'}`}>
      <Icon name="check" weight="solid" />
    </span>
  )
}

export default CheckTag
