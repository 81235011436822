import React, { useEffect, useState } from 'react'
import Currency from '../Currency/Currency'
import './Cart.sass'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import { find, isEmpty } from 'lodash'
import DesktopTable from './DesktopTable/DesktopTable'
import Button from '../../ui/Button/Button'
import { clearCart } from './functions/clearCart'
import getCollection from '../../utils/db/getCollection'
import ProductSlider from '../ProductSlider/ProductSlider'
import MobileTable from './MobileTable/MobileTable'

function Cart() {
  const { cart } = useContext(AuthContext)
  const [fetchedProducts, setFetchedProducts] = useState(null)
  const [products, setProducts] = useState(null)

  useEffect(() => {
    getCollection({ path: 'products', docIdName: 'productId' }).then((data) => {
      setProducts(data)
      updateProducts(data)
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(products)) {
      updateProducts(products)
    }
  }, [cart.products])

  const updateProducts = (prods) => {
    setFetchedProducts(
      cart.products.map((p) => {
        const productData = find(prods, ['productId', p.id])

        return { ...p, ...productData }
      })
    )
  }
  return (
    <section className="InnerSection CartPage">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="CartPage-Container">
              <div className="Cart-TableContent">
                <h3 className="CategoryTitle">Корзина</h3>
                {isEmpty(cart.products) ? (
                  <p className="NoInfo">Нет товаров</p>
                ) : (
                  <div id="cartProductsTable">
                    <div className="DesktopTable">
                      <DesktopTable fetchedProducts={fetchedProducts} />
                    </div>
                    <div className="MobileTable">
                      <MobileTable fetchedProducts={fetchedProducts} />
                    </div>
                    <div className="Cart-TableButtons">
                      <Button
                        title="Удалить все товары"
                        theme="transparent"
                        icon="times"
                        iconPosition="left"
                        style={{ textTransform: 'uppercase' }}
                        onClick={() => clearCart({ cart })}
                      />
                      <Button
                        title="Продолжить покупки"
                        theme="solid"
                        type="navlink"
                        path="/products"
                        fill="accent"
                        icon="angle-right"
                        iconPosition="right"
                        style={{ textTransform: 'uppercase' }}
                        onClick={() => null}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="Cart-Totals">
                <h3 className="CategoryTitle">Ваш заказ</h3>
                <div className="Cart-TotalsBlock">
                  <div className="TotalsLine">
                    Стоимость товаров
                    <p>
                      <span id="productsPrice">
                        {!isEmpty(fetchedProducts)
                          ? fetchedProducts.reduce(
                              (acc, el) => acc + el.price * el.quantity,
                              0
                            )
                          : 0}
                      </span>
                      <Currency />
                    </p>
                    {/* <div className="TotalsLine">
                      Доставка
                      <p>
                        <span id="deliveryPrice">Согласно т</span>
                        <Currency />
                      </p>
                    </div> */}
                    {/* <div className="TotalsLine">
                      Всего
                      <p>
                        <span id="totals">Total</span>
                        <Currency />
                      </p>
                    </div> */}
                  </div>
                  <div className="TotalsButton">
                    <Button
                      title="Оформить заказ"
                      theme="solid"
                      type="navlink"
                      path="/checkout"
                      fill="accent"
                      icon="angle-right"
                      iconPosition="right"
                      style={{ textTransform: 'uppercase' }}
                      onClick={() => null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ProductSlider type="rekomended" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cart
