import React from 'react'
import Button from '../../ui/Button/Button'
import check from './check.png'
import './Success.sass'

function Success() {
  return (
    <section className="InnerSection Success">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Success-Container">
              <p className="Title">Ваш заказ успешно оформлен!</p>
              <p className="Description">
                Наш менеджер свяжется с вами в ближайшее время
              </p>
              <div className="Image-Container">
                <img src={check} alt="Success" />
                {/* <img
                  className="CarImage"
                  src={avatar.publicUrl}
                  alt={carData.title}
                /> */}
              </div>
              <Button
                title="Вернуться на главную"
                theme="solid"
                fill="accent"
                type="navlink"
                icon="angle-right"
                iconPosition="right"
                style={{ textTransform: 'uppercase' }}
                path={'/#home'}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Success
