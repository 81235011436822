import React, { useContext, useEffect, useState } from 'react'
import InfoLine from '../../ui/InfoLine/InfoLine'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './TourCatalog.sass'
import Button from '../../ui/Button/Button'
import Img from '../../ui/Img/Img'
// import { tours } from './tours/index'
import { AuthContext } from '../../App'
import { find, isEmpty, minBy, orderBy } from 'lodash'
import { withRouter } from 'react-router'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import Skeleton from '../../ui/Skeleton/Skeleton'
import location from '../../locale/location'
import getCollection from '../../utils/db/getCollection'

function TourCatalog({ ...router }) {
  const { lang } = useContext(AuthContext)
  const [tours, setTours] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'tours',
      docIdName: 'tourId',
      whereQueries: [{ fieldPath: 'isPublished', op: '==', value: true }],
    }).then((data) => {
      setTours(
        orderBy(
          data.map((d) => {
            const photoString = d.ru.photoLinks
            const array = photoString.split('\n')
            // .map((e) => {
            //   const str = e.split('"')[1]
            //   return str
            // })
            // .filter((e, i) => i !== 0)

            d.avatar = array[0]
            // d.avatar = find(d.photos, ['isAvatar', true]).publicUrl
            return d
          }),
          ['pos'],
          ['asc']
        )
      )
    })
  }, [])

  return (
    <section id="tours-catalog" className="TourCatalog DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title={location[lang].tourCatalog.sectionTitle} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="TourCatalog-Container">
              <div className="Catalog-Tiles">
                {!isEmpty(tours)
                  ? tours.map((t) => (
                      <TourTile
                        key={t.tourId}
                        id={t.tourId}
                        avatar={t.avatar}
                        title={t[lang].title}
                        duration={`${t[lang].duration}`}
                        price={minBy(t[lang].price, 'value').value}
                        season={t[lang].season}
                      />
                    ))
                  : [
                      <TourTileSkeleton />,
                      <TourTileSkeleton />,
                      <TourTileSkeleton />,
                      <TourTileSkeleton />,
                      <TourTileSkeleton />,
                    ]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function TourTile({ id, avatar, title, duration, season, price }) {
  const { lang } = useContext(AuthContext)
  return (
    <NavHashLink
      to={`/tours/${id}#tour`}
      className="TourTile"
      smooth
      activeClassName="Site-NavLink_theme_active"
      scroll={(el) => scrollWithOffset(el, -64)}
    >
      <div className="Avatar">
        <Img src={avatar} />
      </div>
      <div className="Tile-Content">
        <p className="Title">{title}</p>
        <InfoLine
          title={location[lang].tourCatalog.duration.title}
          content={duration}
        />
        <InfoLine title={location[lang].tourCatalog.season} content={season} />
        <InfoLine
          title={location[lang].tourCatalog.price}
          content={`${location[lang].tourCatalog.from} ${price} ${location[lang].tourCatalog.currency.title}`}
        />
      </div>
      <Button
        title={location[lang].tourCatalog.detailsButton}
        fill="black"
        theme="solid"
      />
    </NavHashLink>
  )
}
function TourTileSkeleton() {
  return (
    <div className="TourTileSkeleton">
      <div className="Avatar">
        <Skeleton />
      </div>
      <div className="Tile-Content">
        <p className="Title">
          <Skeleton width={90} />
        </p>
        <div className="SkeletonContent">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      </div>
      <div className="SkeletonButton">
        <Skeleton width={160} height={32} />
      </div>
    </div>
  )
}

export default withRouter(TourCatalog)
