import React, { useEffect, useState } from 'react'
import './Products.sass'
import getCollection from '../../utils/db/getCollection'
import promiseAllValues from '../../utils/promiseAllValues'
import {
  cloneDeep,
  find,
  isArray,
  isEmpty,
  isNull,
  orderBy,
  toNumber,
} from 'lodash'
import { ProductTile } from '../ProductSlider/ProductSlider'
import CheckTag from '../CheckTag/CheckTag'
import { NavLink, withRouter } from 'react-router-dom'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Icon from '../../ui/Icon/Icon'

function Products({ ...router }) {
  const categoryId = router.match.params.id
  const [productsAndCats, setProductsAndCats] = useState(null)

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'categories',
        docIdName: 'categoryId',
      }).then((data) => {
        const cats = orderBy(
          data
            .filter((d) => !d.isDeleted && true)
            .map((d) => {
              d.position = toNumber(d.position)
              return d
            }),
          ['position'],
          ['asc']
        )
        const catsWithActive = cats.map((c) => {
          c.isActive = c.category === categoryId
          return c
        })

        if (!find(catsWithActive, ['isActive', true])) {
          catsWithActive[0].isActive = true
        }

        return catsWithActive
      }),
      products: getCollection({
        path: 'products',
        docIdName: 'productId',
      }).then((data) =>
        orderBy(
          data.filter((d) => !d.isDeleted && true),
          ['created'],
          ['desc']
        )
      ),
    }).then((result) => setProductsAndCats(result))
  }, [])

  useEffect(() => {
    if (!isEmpty(productsAndCats)) {
      const clone = cloneDeep(productsAndCats)
      clone.categories.map((c) => {
        c.isActive = c.categoryId === categoryId
        return c
      })
    }
  }, [categoryId])

  return (
    <section className="InnerSection Products">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {!isNull(productsAndCats) && (
              <>
                <div className="BreadCrumbs">
                  <NavLink to="/">Главная</NavLink>
                  <Icon name="angle-right" />
                  <span className="ActiveCat">
                    {
                      find(productsAndCats.categories, [
                        'categoryId',
                        categoryId,
                      ]).title
                    }
                  </span>
                </div>
                <div className="Products-Container">
                  <div className="Categories-Nav">
                    {productsAndCats.categories.map((c) => (
                      <NavLink
                        to={`/products/${c.categoryId}`}
                        className={`Category`}
                        activeClassName="Category_theme_active"
                      >
                        <CheckTag /> {c.title}
                      </NavLink>
                    ))}
                  </div>
                  <div className="Products-Content">
                    <SectionTitle
                      title={
                        find(productsAndCats.categories, [
                          'categoryId',
                          categoryId,
                        ]).title
                      }
                    />
                    <img
                      className="Products-Banner"
                      src={
                        find(
                          find(productsAndCats.categories, [
                            'categoryId',
                            categoryId,
                          ]).photos,
                          ['isAvatar', true]
                        ).publicUrl
                      }
                      alt=""
                    />
                    <div className="Products-Tiles">
                      {isEmpty(
                        productsAndCats.products.filter((p) =>
                          isArray(p.category)
                            ? p.category.includes(categoryId) && true
                            : p.category === categoryId
                        )
                      ) ? (
                        <p className="NoInfo">Нет товаров</p>
                      ) : (
                        productsAndCats.products
                          .filter((p) =>
                            isArray(p.category)
                              ? p.category.includes(categoryId) && true
                              : p.category === categoryId
                          )
                          .map((p) => (
                            <div className="ProductTile">
                              <ProductTile key={p.productId} {...p} />
                            </div>
                          ))
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default withRouter(Products)
