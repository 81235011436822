import { updateDoc } from '../../../utils/db/updateDoc'

export const clearProduct = ({ id, cart }) => {
  const newProducts = cart.products.filter((p) => p.id !== id)

  return updateDoc({
    path: 'carts',
    docId: cart.id,
    data: { products: newProducts },
  })
}
