import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Delivery.sass'

function Delivery() {
  return (
    <section className="InnerSection Page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title={'Оплата и доставка'} />
            <div className="PageText">
              <p>
                Мы доставляем букеты 7 дней в неделю,{' '}
                <span className="Bolder">с 8:00 до 22:00</span>.
              </p>
              <p>
                Заказы день в день принимаются до 18:00. Заказы, поступившие
                после 18:00, могут быть исполнены лишь на следующий день.
              </p>
              <p>
                Доставим бесплатно букет из нашего каталога в промежутке от 90
                до 180 минут после оформления заказа, через 3 часа —
                индивидуальный авторский. Доставка к определенному
                фиксированному времени оговаривается индивидуально с менеджером.
              </p>
              <p>
                <span className="Bolder">
                  Важно: стоимость доставки с 8:00 до 9:00 и после 20:00 –
                  400руб
                </span>
              </p>
              <p>
                Доставка производится в пределах города Мурманска и ближайших
                районов. Зону покрытия доставки за пределами города можно
                уточнить у менеджера по любому из указанных на сайте телефонов.
              </p>
              <p>
                <span className="Bolder">
                  Внимание! В праздничные дни стоимость доставки может
                  корректироваться
                </span>
                .
              </p>
              <p>
                <span className="Bolder">Самовывоз</span>
                <br /> <br />
                Забрать свой заказ вы можете по адресу:
                <br />
                г.Мурманск, ул. Свердлова, д.19 цветочный магазин «Диантус»
              </p>
              <p>
                <span className="Bolder">Способы оплаты</span>
                <br /> <br />
                Наличный расчет
                <br />
                - Наличными или с помощью банковской картой в магазине при
                самовывозе
                <br />
                - Если товар доставляется курьером, то оплата осуществляется
                наличными курьеру в руки.
                <br />
                <br />
                Банковской картой
                <br />
                Для выбора оплаты товара с помощью банковской карты при
                подтверждении заказа звонком нашим менеджером вам будет выслана
                ссылка на соответствующую страницу Оплаты заказа банковской
                картой.
                <br />
                <br />
                Безналичный расчет
                <br />
                Преимущественно для юридических лиц. Мы предоставим все
                необходимые документы (УПД, накладная, счёт на оплату).
              </p>
              <p>
                <span className="Bolder">Возврат товара</span>
                <br /> <br />
                Цветы являются живым товаром. К отношениям между Покупателем и
                Продавцом применяется право Российской Федерации.
                <br />
                <br />
                В соответствии с Законом Российской Федерации «О защите прав
                потребителей» от 07.02.1992 № 2300-1 (в ред. от 25.10.2007г.) и
                Постановлением Правительства Российской Федерации от 19.01.1998
                № 55 (в ред. 27.03.2007г.) срезанные цветы и горшечные растения
                обмену и возврату не подлежат (указаны в Перечне
                непродовольственных товаров надлежащего качества, не подлежащих
                возврату или обмену).
                <br />
                <br />
                Покупатель Интернет-магазина имеет право отказаться от получения
                товара в момент доставки, если доставлен товар ненадлежащего
                качества (на основании п.3 ст. 497 ГК РФ, статья 21 Закона "О
                защите прав потребителей").
                <br />
                <br />
                Возврат переведённых средств, производится на ваш банковский
                счёт в течение 5-30 рабочих дней (срок зависит от банка, который
                выдал вашу банковскую карту). После электронной оплаты оформить
                возврат наличными нельзя.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Delivery
