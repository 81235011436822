import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyBBK3rrg0S3Qc7hbU8P2f3vgl6sq5WP5uQ',
  authDomain: 'diantus-a0269.firebaseapp.com',
  projectId: 'diantus-a0269',
  storageBucket: 'diantus-a0269.appspot.com',
  messagingSenderId: '156096145125',
  appId: '1:156096145125:web:a9297cb84626dbcbccd60c',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
