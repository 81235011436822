import React, { useContext, useEffect, useState } from 'react'
import Icon from '../../../ui/Icon/Icon'
import Currency from '../../Currency/Currency'
import './DesktopTable.sass'
import { AuthContext } from '../../../App'
import Spinner from '../../../ui/Spinner/Spinner'
import { find, isEmpty, isNull, toNumber } from 'lodash'
import { tr } from 'date-fns/locale'
import getCollection from '../../../utils/db/getCollection'
import Button from '../../../ui/Button/Button'
import { increaseProductQuantity } from '../functions/increaseProductQuantity'
import { decreaseProductQuantity } from '../functions/decreaseProductQuantity'
import { clearProduct } from '../functions/clearProduct'

function DesktopTable({ fetchedProducts }) {
  const { cart } = useContext(AuthContext)
  return (
    <table className="CartTable">
      <thead>
        <tr>
          <th></th>
          <th>Товар</th>
          <th>Количество</th>
          <th>Цена</th>
          <th>Сумма</th>
        </tr>
      </thead>
      <tbody>
        {!isNull(fetchedProducts) ? (
          fetchedProducts.map((p) => {
            const img = find(p.photos, { isAvatar: true })
            return (
              <tr>
                <td className="CartTable-DeleteIcon">
                  <Icon
                    name="trash-alt"
                    onClick={() => clearProduct({ id: p.id, cart })}
                  />
                </td>
                <td>
                  <div className="CartTable-ProductInfo">
                    <div className="Product-Avatar">
                      <img src={img ? img.publicUrl : null} alt={p.title} />
                    </div>
                    <div className="Product-Details">
                      <h4>{p.title}</h4>
                      <p>{p.description}</p>
                    </div>
                  </div>
                </td>
                <td className="CartTable-QuantityBlock">
                  <Button
                    theme="circle"
                    fill="accent"
                    icon="minus"
                    // iconPosition="right"
                    style={{ textTransform: 'uppercase' }}
                    onClick={() =>
                      decreaseProductQuantity({ id: p.productId, cart })
                    }
                  />
                  <span className="QInput">{p.quantity}</span>
                  <Button
                    theme="circle"
                    fill="accent"
                    icon="plus"
                    // iconPosition="right"
                    style={{ textTransform: 'uppercase' }}
                    onClick={() =>
                      increaseProductQuantity({ id: p.productId, cart })
                    }
                  />
                </td>
                <td className="CartTable-ProdPrice">
                  <span className="ProdSinglePrice">{p.price}</span>
                  <Currency />
                </td>
                <td className="CartTable-ProdPrice">
                  <span className="ProdTotalPrice">
                    {toNumber(p.price) * p.quantity}
                  </span>
                  <Currency />
                </td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={5}>
              <Spinner type="module" />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default DesktopTable
