export const questions = [
  {
    question: 'Что взять с собой',
    answer: `
    <ul>
      <li>Документы: паспорт и страховой полис. Личные вещи и снаряжение (рекомендация):</li>
      <li>утепленные брюки и куртка</li>
      <li>непродуваемая шапка</li>
      <li>непромокаемые треккинговые кроссовки или ботинки</li>
      <li>хороший дождевик или штормовка с капюшоном</li>
      <li>термобелье</li>
      <li>банные принадлежности</li>
      <li>небольшой рюкзак для радиальных выходов</li>
      <li>личная аптечка и термос (по желанию)</li>
      <li>средства от комаров и мошкары (например, «Off», «Москитол», «Тайга»).</li>
    </ul>
    `,
  },
  {
    question: 'Какая будет погода',
    answer: `
    <p>Большая часть Кольского полуострова находится за Полярным кругом. Из-за влияния Белого и Баренцева морей и теплых морских течений погода бывает очень переменчива и непредсказуема.</p>

    <p>Июль — самый теплый месяц на Кольском полуострове и период полярных дней, когда солнце не заходит за горизонт. Погода в этом время обычно солнечная и теплая: дневная температура в среднем составляет +15-18°С, ночью опускается до +9°С.</p>

    <p>Средняя температура августа: +12-15°С днем и +5-8°С ночью.</p>

    <p>В сентябре становится прохладнее, могут быть сильные ветра и дожди. Температура воздуха днем составлет в среднем +10°С, ночью опускается до +4°С.</p>

    <p>С конца августа на Кольском полуострове можно наблюдать северное сияние.</p>
    `,
  },
  {
    question: 'Какой уровень сложности тура',
    answer: `
    Вас ожидает умеренная физическая нагрузка. Все туры достаточно комфортные. Уровень сложности низкий, не требует определенных физических усилий или каких-то специальных навыков.
    `,
  },
  {
    question: 'Получиться ли посмотреть на китов',
    answer: `
    Такая вероятность точно есть, но однозначно мы не можем обещать встречу с китами. Морских котиков, белуг, касаток, дельфинов увидеть большая вероятность. Самое лучшее время наблюдения за китами с мая по сентябрь.
    `,
  },
  {
    question: 'Ограничение в питании',
    answer: `
    ВАЖНО! Если у вас есть ограничения по питанию (вегетарианство, аллергия и пр.), обязательно необходимо сообщить детали в заявке на бронирование для возможной корректировки меню. Мы поможем составить альтернативные варианты питания на маршруте.`,
  },
  {
    question: 'Участие детей',
    answer: `
    Оптимально брать с собой детей в возрасте от 8-ми лет. Главное, чтобы им было тепло как днем, так и вечером. Вы должны понимать, что если дети начнут капризничать в туре на сияние, то гид не сможет развернуть автобус обратно в Мурманск. Для детей до 14-ти у нас предусмотрены дополнительные скидки, уточняйте при бронировании.
    `,
  },
  {
    question: 'Кто меня встретит в аэропорту',
    answer: `
    В аэропорту Мурманска или в AZIMUT Отель Мурманск Вас встретят наши гиды на комфортабельном транспорте. Гиды заранее свяжутся с Вами и расскажут все детали тура. Трансфер оплачивается отдельно.
    `,
  },
  {
    question: 'COVID-19 в Мурманске',
    answer: `
    Режим 14 дней на самоизоляции соблюдать НЕ нужно. Все гостиницы регулярно проводят дезинфекцию и другие виды профилактики, но для заселения и посещения мест показа и досуга необходимы ПЦР тесты или QR-код, детям тоже. Не забывайте взять с собой маски, антисептик и перчатки, т.к. в общественные места (кафе, супермаркеты, музеи) без средств индивидуальной защиты вас могут не пустить.
    `,
  },
  {
    question: 'Все о Северном сиянии',
    answer: `
    С сентября по апрель сезон северного сияния (осень, зима, первая половина весны). В этот период туристы наблюдают северное сияние невооруженным взглядом. Именно в этот период мы проводим наши туры за северным сиянием. Сбор туристов гидом может начаться с 20:00. Длительность тура 3-6 часов в зависимости от того, как быстро мы сможем увидеть северное сияние. Приехать в Мурманск лучше в первую половину дня, чтобы сразу отправится на поиски Северного сияния. Северное сияние не такое яркое как на фото. Имейте ввиду, что все профессиональные фото проходят тщательную обработку и коррекцию. Также здесь есть такой фактор как светочувствительность. Объектив камеры воспринимает гораздо больше солнечного света, чем человеческий глаз. Поэтому в реальности северное сияние не такое насыщенное, об этом стоит помнить. Мы всегда предупреждаем наших туристов об этом, чтобы не было слишком больших ожиданий на этот счет.
    `,
  },
]
