import React, { useContext, useEffect, useState } from 'react'
import './ProductSlider.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Carousel from '../../ui/Carousel/Carousel'
import { find, intersection, isArray, isEmpty, orderBy, shuffle } from 'lodash'
import getCollection from '../../utils/db/getCollection'
import Currency from '../Currency/Currency'
import promiseAllValues from '../../utils/promiseAllValues'
import { NavLink } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import Button from '../../ui/Button/Button'
import { increaseProductQuantity } from '../Cart/functions/increaseProductQuantity'
import { AuthContext } from '../../App'
import { decreaseProductQuantity } from '../Cart/functions/decreaseProductQuantity'

function ProductSlider({ type = 'slider' }) {
  const [products, setProducts] = useState([])

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'categories',
        docIdName: 'categoryId',
      }),
      products: getCollection({
        path: 'products',
        docIdName: 'productId',
      }).then((data) => {
        if (type === 'slider') {
          return orderBy(
            data.filter((d) => !d.isDeleted && true),
            ['created'],
            ['desc']
          )
        } else {
          return shuffle(
            data.filter((d) => {
              const catsToSort = [
                'CKKI3ZZ06bFQRdLgRgpl',
                'EMFe2kCpvedxEZKbkVlj',
                'mMZ706rVl3HIgd23CgcM',
              ]
              if (isArray(d.category)) {
                return !isEmpty(intersection(catsToSort, d.category))
              } else {
                return catsToSort.includes(d.category)
              }
            })
          )
        }
      }),
    }).then((result) => {
      setProducts(
        result.products
          .filter((p) => p.isPublished && true)
          .map((p) => {
            const category = isArray(p.category)
              ? find(result.categories, ['categoryId', p.category[0]])
              : find(result.categories, ['categoryId', p.category])
            p.categoryName = category ? category.title : null
            return p
          })
      )
    })
  }, [])

  return (
    <section className="ProductSlider">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle
              title={
                type === 'slider'
                  ? `Новые поступления`
                  : 'С этим товаром покупают'
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="ProductsSlider-Container">
              {!isEmpty(products) && (
                <Carousel
                  element="ProductsSlider-Carousel"
                  options={{
                    useArrows: true,
                    type: 'carousel',
                    gap: 24,
                    perView: 4,
                    autoplay: 3000,
                    breakpoints: {
                      1024: {
                        perView: 3,
                        gap: 16,
                      },
                      768: {
                        perView: 2,
                        gap: 16,
                      },
                      576: {
                        perView: 1,
                        gap: 0,
                      },
                    },
                    elementClassName: 'ProductTile',
                  }}
                >
                  {products.map((p) => (
                    <ProductTile {...p} />
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export function ProductTile({
  productId,
  category,
  title,
  photos,
  description,
  price,
  categoryName,
}) {
  const img = find(photos, { isAvatar: true })

  const { cart } = useContext(AuthContext)
  const productInCart = find(cart.products, ['id', productId])

  return (
    <>
      <div className="ProductBlock">
        {!isEmpty(categoryName) && (
          <NavLink className="Product-Cat" to={`/products/${category}`}>
            {categoryName} <Icon name="long-arrow-right" />
          </NavLink>
        )}
        <a href={img ? img.publicUrl : null} data-fancybox={productId}>
          <img
            src={img ? img.publicUrl : null}
            alt={title}
            className="Product-Img"
          />
        </a>
        {photos.length > 1 && (
          <div style={{ display: 'none' }}>
            {photos.map((p, i) =>
              i === 0 ? (
                ''
              ) : (
                <a data-fancybox={productId} href={p.publicUrl}>
                  <img src={p.publicUrl} />
                </a>
              )
            )}
          </div>
        )}
        <h4 className="Product-Title">{title}</h4>
        <div className="Product-Desc">{description}</div>
      </div>
      <div className="Product-Buttons">
        <div className="Product-Price">
          {price} <Currency />
        </div>
        {productInCart ? (
          <div className="QuantityManager">
            <div
              className="RoundedIcon"
              onClick={() => decreaseProductQuantity({ cart, id: productId })}
            >
              -
            </div>
            <div className="Quantity">
              {find(cart.products, ['id', productId]).quantity}
            </div>
            <div
              className="RoundedIcon"
              onClick={() => increaseProductQuantity({ cart, id: productId })}
            >
              +
            </div>
          </div>
        ) : (
          <Button
            title="В корзину"
            theme="solid"
            fill="accent"
            icon="angle-right"
            iconPosition="right"
            style={{ textTransform: 'uppercase' }}
            onClick={() => increaseProductQuantity({ cart, id: productId })}
          />
        )}
      </div>
    </>
  )
}

export default ProductSlider
