import React, { useContext } from 'react'
import './Partners.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import hvoya from './assets/hvoya.svg'
import sme from './assets/sme.png'
import vershina from './assets/vershina.jpg'
import dolina from './assets/dolina.jpg'
import asoc from './assets/as.jpg'
import Img from '../../ui/Img/Img'
import Carousel from '../../ui/Carousel/Carousel'
import { AuthContext } from '../../App'
import location from '../../locale/location'
import ecovillage from './assets/ecovillage.jpg'
import kiza from './assets/kiza.jpg'
import xworld from './assets/xworld.jpg'
import ozero from './assets/ozero.jpg'
import { useState } from 'react'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, orderBy } from 'lodash'

function Partners() {
  const { lang } = useContext(AuthContext)
  const [partners, setPartners] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'partners',
      docIdName: 'partnerId',
    }).then((docs) => setPartners(orderBy(docs, ['created'], ['desc'])))
  }, [])

  return (
    <section id="partners" className="Partners DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle
              title={location[lang].partners.sectionTitle}
              theme="light"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Partners-Container">
              {!isEmpty(partners) &&
                partners.map((p) => (
                  <PartnerTile
                    key={p.partnerId}
                    src={find(p.photos, ['isAvatar', true]).publicUrl}
                    link={p.link}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function PartnerTile({ src, link }) {
  return (
    <a href={link} target="blank" className="PartnerTile">
      <Img src={src} />
    </a>
  )
}

export default Partners
