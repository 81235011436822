import React from 'react'
import './Hero.sass'
import { withRouter } from 'react-router-dom'
import parse from 'html-react-parser'
import { NavHashLink } from 'react-router-hash-link'
import Icon from '../../ui/Icon/Icon'
import hero1 from './assets/1.jpg'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import Button from '../../ui/Button/Button'
import location from '../../locale/location'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import { find, isEmpty } from 'lodash'
import ReactPlayer from 'react-player'

function Hero({ title, background, detailTourButton = false, detailLink }) {
  const { settings, lang, setShowContactUs } = useContext(AuthContext)

  const t = title ? title : settings.title

  let avatar = null
  if (settings) {
    const av = find(settings.photos, ['isAvatar', true])
    if (av) {
      avatar = av.publicUrl
    }
  }

  if (background) {
    avatar = background
  }

  return (
    <div id="home" className="Site-Hero">
      <div className="SiteHero-Container">
        <div
          className="Offer-Container"
          style={{ backgroundImage: `url(${avatar})` }}
        >
          {!isEmpty(settings.homeVideo && settings.homeVideo.publicUrl) &&
            !title && (
              <div className="Offer-Video">
                <ReactPlayer
                  url={settings.homeVideo.publicUrl}
                  width="100%"
                  height="100%"
                  playing
                  muted
                  loop
                  // controls
                  // light={light}
                  // light={
                  //   find(data.photos, ['isTeaser', true]) &&
                  //   find(data.photos, ['isTeaser', true]).publicUrl
                  // }
                />
              </div>
            )}
          <div className="Offer">
            <div className="Offer-Content">
              <h1 className="Offer-Title">{t}</h1>
              <p className="Offer-Description">{settings.description}</p>
              <div className="Offer-Buttons">
                {detailTourButton ? (
                  <>
                    <NavHashLink
                      to={detailLink}
                      smooth
                      scroll={(el) => scrollWithOffset(el, -64)}
                    >
                      <Button
                        title={location[lang].tourCatalog.detailsButton}
                        fill="accent"
                        theme="solid"
                      />
                    </NavHashLink>
                    <NavHashLink
                      to="#tour-constructor"
                      smooth
                      scroll={(el) => scrollWithOffset(el, -64)}
                    >
                      <Button
                        title={'Рассчитать стоимость тура'}
                        // title={location[lang].hero.constructorButton}
                        theme="transparent-hug"
                      />
                    </NavHashLink>
                  </>
                ) : (
                  <>
                    {/* <NavHashLink
                      to="/#tours-catalog"
                      smooth
                      scroll={(el) => scrollWithOffset(el, -64)}
                    > */}
                    <Button
                      title={'Подобрать тур'}
                      fill="accent"
                      theme="solid"
                      onClick={() =>
                        setShowContactUs({
                          show: true,
                          title: 'Подобрать тур',
                          description:
                            'Заполните форму, мы свяжемся с вами в течение 10 минут, подберем лучший тур',
                          emailSubject: 'Запрос на подбор тура',
                          target: 'podbor_ok',
                        })
                      }
                    />
                    {/* </NavHashLink> */}
                    <NavHashLink
                      to="/#tour-constructor"
                      smooth
                      scroll={(el) => scrollWithOffset(el, -64)}
                    >
                      <Button
                        // title={location[lang].hero.constructorButton}
                        title={'Рассчитать стоимость тура'}
                        theme="transparent-hug"
                      />
                    </NavHashLink>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Offer-SocialBlock">
          {/* <a href={settings.vkLink} target="blank">
            <Icon name="vk" weight="brand" />
          </a>
          <a href={settings.faceLink} target="blank">
            <Icon name="facebook-f" weight="brand" />
          </a>
          <a href={settings.instLink} target="blank">
            <Icon name="instagram" weight="brand" />
          </a> */}
          <a href={settings.tmLink} target="blank">
            <Icon name="telegram" weight="brand" />
          </a>
          <a href={settings.whatLink} target="blank">
            <Icon name="whatsapp" weight="brand" />
          </a>
          <a href={settings.viberLink} target="blank">
            <Icon name="viber" weight="brand" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Hero)
