import React, { useContext } from 'react'
import './Home.sass'
import Hero from '../../components/Hero/Hero'
import Feeds from '../../components/Feeds/Feeds'
import Faq from '../../components/Faq/Faq'
import TourCatalog from '../../components/TourCatalog/TourCatalog'
import TourConstructor from '../../components/TourConstructor/TourConstructor'
import Partners from '../../components/Partners/Partners'
import ContactUs from '../../components/ContactUs/ContactUs'
import LegacyInfo from '../../components/LegacyInfo/LegacyInfo'
import { AuthContext } from '../../App'
import Seo from '../../ui/Seo/Seo'
import Top from '../../components/Top/Top'
import Categories from '../../components/Categories/Categories'
import ProductSlider from '../../components/ProductSlider/ProductSlider'
import About from '../../components/About/About'
import Benefits from '../../components/Benefits/Benefits'
import Slider from '../../components/Slider/Slider'

function Home() {
  const { settings } = useContext(AuthContext)
  return (
    <div className="Home">
      <Slider />
      <Categories />
      <ProductSlider />
      <About />
      <Benefits />
    </div>
  )
}

export default Home
