import React, { useContext } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Icon from '../../ui/Icon/Icon'
import './Faq.sass'
import Button from '../../ui/Button/Button'
import toggleSpoiler from '../../utils/toggleSpoiler'
import { AuthContext } from '../../App'
import { questions } from './questions'
import parse from 'html-react-parser'
import location from '../../locale/location'

function Faq() {
  const { lang, setShowContactUs } = useContext(AuthContext)

  return (
    <section id="faq" className="Faq DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title={location[lang].faq.sectionTitle} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Faq-Container">
              {questions.map((q) => (
                <Question
                  key={q.question}
                  question={q.question}
                  answer={q.answer}
                />
              ))}
            </div>
            <div className="Faq-Buttons">
              <Button
                title={location[lang].faq.buttonTitle}
                theme="solid"
                fill="black"
                onClick={() =>
                  setShowContactUs({
                    show: true,
                    title: 'Задать вопрос',
                    emailSubject: 'Вопрос от пользователя сайта',
                    target: 'vopros_ok',
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Question({ question, answer }) {
  return (
    <div className="Question">
      <div className="Question-Header" onClick={toggleSpoiler}>
        <div className="Question-Icon">
          <Icon name="plus" className="qIcon-Plus" />
          <Icon name="minus" className="qIcon-Minus" />
        </div>
        <p className="Question-Title">{question}</p>
      </div>
      <div className="Question-Body">{parse(answer)}</div>
    </div>
  )
}

export default Faq
