export const en = {
  links: {
    tours: 'Our tours',
    constructor: 'Tour constructor',
    feeds: 'Reviews',
    partners: 'Partnerships',
    contacts: 'Contacts',
  },
  hero: {
    title:
      'iTerra for cozy vacations<br/><span className="Bolder">on the edge of the world!</span>',
    description:
      'Big crew of experts, high service, new transport<br/> and individual care for every guest',
    tourButton: 'Choose your tour',
    constructorButton: 'Create new tour',
  },
  tourCatalog: {
    sectionTitle: 'Our tours',
    duration: {
      title: 'Time duration',
      hours: 'h.',
      days: 'd.',
      day: 'Day',
    },
    season: 'Season',
    hours: 'h.',
    min: 'min.',
    days: 'd.',
    price: 'Price',
    program: 'Tour itinerary',
    from: 'from',
    currency: {
      title: 'rub',
      group: 'rub. pax in group',
      individual: 'rub. for individual tour',
      person: 'rub. pax for',
    },
    included: 'Included',
    howToPrepare: 'Your equipment',
    detailsButton: 'More details',
    bookTourButton: 'Book a tour',
  },

  tourConstructor: {
    sectionTitle: 'Tour constructor',
    title: 'Create your own tour!',
    description:
      'Please choose your preferable options and our manager will contact you soon!',
    form: {
      step1: 'When we begin?',
      step2: 'Choose destinations',
      step3: 'Amount of people',
    },
    button: 'Get offer',
  },

  feeds: {
    sectionTitle: 'Our guests rewievs',
    readMore: 'More',
    close: 'Close',
  },
  faq: {
    sectionTitle: 'FAQ: frequently asking questions',
    buttonTitle: 'Ask your question',
  },
  partners: { sectionTitle: 'Partnerships' },
  contactForm: {
    onError: 'Please fill all the forms',
    onSuccess: 'Thank you for your request! Our manager will contact you soon',
    contactUs: 'Contact us',
    description: 'We will answer to all your questions in few hours',
    sendForm: 'Send',
  },
}
