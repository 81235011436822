import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Benefits.sass'
import b1 from './icons/1.svg'
import b2 from './icons/2.svg'
import b3 from './icons/3.svg'
import b4 from './icons/4.svg'

function Benefits() {
  const benefits = [
    {
      icon: b1,
      title: '100% гарантия качества',
      desc:
        'Благодаря собственному производству мы доставляем только свежие цветы!',
    },
    {
      icon: b2,
      title: 'Быстрая доставка',
      desc:
        'Мы доставим букет в течение трех часов с момента заказа, либо доставим на любое точное время',
    },
    {
      icon: b3,
      title: 'Бесплатная открытка к букету',
      desc:
        'Благодаря собственному производству мы доставляем только свежие цветы!',
    },
    {
      icon: b4,
      title: 'Гарантируем эффект сюрприза',
      desc:
        'Получатель узнает о Вас только по Вашей просьбе или из текста открытки',
    },
  ]

  return (
    <section className="Benefits">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Наши преимущества" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Benefits-Container">
              {benefits.map((b) => (
                <div className="Benefit">
                  <div className="Benefit-Icon">
                    <img src={b.icon} alt={b.title} />
                  </div>
                  <div className="Benefit-Title">{b.title}</div>
                  <div className="Benefit-Desc">{b.desc}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits
