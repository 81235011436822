import { cloneDeep, find, isEmpty } from 'lodash'
import { updateDoc } from '../../../utils/db/updateDoc'

export const increaseProductQuantity = ({ id, cart }) => {
  let newProducts = cloneDeep(cart.products)

  if (isEmpty(newProducts)) {
    newProducts.push({
      id,
      quantity: 1,
    })
  } else {
    const productInCart = find(newProducts, ['id', id])
    if (productInCart) {
      productInCart.quantity += 1
    } else {
      newProducts.push({
        id,
        quantity: 1,
      })
    }
  }

  return updateDoc({
    path: 'carts',
    docId: cart.id,
    data: { products: newProducts },
  })
}
