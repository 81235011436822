import React from 'react'
import Icon from '../../ui/Icon/Icon'
import { NavLink } from 'react-router-dom'
import './Footer.sass'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'

function Footer() {
  return (
    <footer id="contacts" className="Footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Footer-Container">
              <div className="Infoline">
                <div className="Infoline-Icon">
                  <Icon name="mobile" />
                </div>
                <div>
                  <h4 className="Footer-BlockTitle">Инфолиния</h4>
                  <a href="tel:+7 (8152) 60-19-40">
                    <div className="Infoline-Phone">+7 (8152) 60-19-40</div>
                  </a>
                  <p className="Footer-MainText">Режим приема звонков:</p>
                  <p className="Footer-SimpleText">Пн-Вс: с 09:00 до 20:00</p>
                  <p className="Footer-MainText">
                    Мы в соц. сетях:
                    <div className="Infoline-Soc">
                      <div className="Soc-Icons">
                        <a
                          href="https://api.whatsapp.com/send?phone=79646801940"
                          target="blank"
                        >
                          <Icon name="whatsapp" weight="brand" />
                        </a>
                        <a href="https://vk.com/cvetydiantus51" target="blank">
                          <Icon name="vk" weight="brand" />
                        </a>
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div className="Footer-LinkBlock ClientsBlock">
                <h4 className="Footer-BlockTitle">Клиенту</h4>
                <div className="Footer-Menu">
                  <NavLink to="/delivery" className={'Footer-Link'}>
                    Оплата и доставка
                  </NavLink>
                  <a
                    href="/docs/sogl.pdf"
                    target="blank"
                    className={'Footer-Link'}
                  >
                    Согласие на обработку персональных данных
                  </a>
                  <a
                    href="/docs/policy.pdf"
                    target="blank"
                    className={'Footer-Link'}
                  >
                    Политика конфиденциальности
                  </a>
                </div>
              </div>

              <div className="Footer-LinkBlock PartnersBlock">
                <h4 className="Footer-BlockTitle">Партнёрам</h4>
                <div className="Footer-Menu">
                  <NavHashLink
                    to="/#about"
                    className={'Footer-Link'}
                    smooth
                    activeClassName="Site-NavLink_theme_active"
                    scroll={(el) => scrollWithOffset(el, -128)}
                  >
                    О компании
                  </NavHashLink>
                  <a
                    href="https://diantusopt.ru"
                    target="blank"
                    className={'Footer-Link'}
                  >
                    Оптовые закупки
                  </a>
                </div>
              </div>

              <div className="Footer-LinkBlock ContactsBlock">
                <h4 className="Footer-BlockTitle">Контакты</h4>
                <p className="Footer-SimpleText">
                  г. Мурманск, ул. Свердлова, д. 19
                </p>
                <p className="Footer-MainText">
                  E-mail: diantus.roznica@mail.ru
                </p>
                <p>
                  <a
                    className="Footer-AccentLink"
                    href="tel: +7 (8152) 60-19-40"
                  >
                    Тел: +7 (8152) 60-19-40
                  </a>
                </p>
                <p>
                  <a
                    className="Footer-AccentLink"
                    href="tel: +7 (964) 680-19-40"
                  >
                    Тел: +7 (964) 680-19-40
                  </a>
                </p>
                <p>
                  <a
                    href="https://yandex.ru/maps/23/murmansk/?ll=33.114050%2C68.997042&mode=usermaps&source=constructorLink&um=constructor%3A00a8990ffabb436374cb2c9ea7831f9f1276467ad7d5fdcec5c2ba78fa0bc87e&z=17"
                    target="blank"
                    className="Footer-AccentLink"
                  >
                    Схема проезда
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
