import React, { useEffect, useState } from 'react'
import Carousel from '../../ui/Carousel/Carousel'
import './Slider.sass'
import getDoc from '../../utils/db/getDoc'
import { isEmpty, isNull } from 'lodash'
import useWindowSize from '../../hooks/useWindowSize'

function Slider() {
  const [slides, setSlides] = useState(null)
  console.log('🚀 ~ Slider ~ slides:', slides)
  const screen = useWindowSize()
  console.log('🚀 ~ Slider ~ screen:', screen)

  useEffect(() => {
    getDoc({ path: 'settings', docId: 'BqOykJugZ5mnBAAPocze' }).then((data) => {
      setSlides(data.photos)
    })
  }, [])
  return (
    <section className="Offer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {!isEmpty(slides) ? (
              <Carousel
                element="Slider"
                options={{
                  useArrows: true,
                  useDots: true,
                  type: 'carousel',
                  autoplay: 5000,
                }}
              >
                {slides
                  .filter((s, i) => {
                    if (screen.width <= 576) {
                      return i % 2 !== 0
                    } else {
                      return i % 2 === 0
                    }
                  })
                  .map((s) => (
                    <img src={s.publicUrl} />
                  ))}
              </Carousel>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Slider
