import React, { useState, useEffect, useContext, useRef } from 'react'
import './TourConstructor.sass'
import cloneDeep from 'lodash.clonedeep'
import { find, isEmpty } from 'lodash'
import constuctorBg from './constructorBg.jpg'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { createForm } from '../../utils/newforms/createForm'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import FieldRender from '../../utils/newforms/render/FieldRender'
import Button from '../../ui/Button/Button'
import { tours } from '../TourCatalog/tours'
import { AuthContext } from '../../App'
import Icon from '../../ui/Icon/Icon'
import useWindowSize from '../../hooks/useWindowSize'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import getFormValues from '../../utils/newforms/getFormValues'
import location from '../../locale/location'
import getCollection from '../../utils/db/getCollection'
import DatePicker from '../../ui/DatePicker/DatePicker'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale/ru'
import { convertDateFromString } from '../../utils/date/convertDateFromString'
import { usePopUp } from '../../hooks/usePopUp'

function TourConstructor() {
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [form, setForm] = useState(
    createForm({ formPattern: new TourConstructorForm() })
  )
  console.log('🚀 ~ TourConstructor ~ form:', form)
  const contRef = useRef()
  const calRef = useRef()

  const [showErrors, setShowErrors] = useState(false)
  const [tours, setTours] = useState(null)
  const { width } = useWindowSize()
  const { setShowContactUs, lang } = useContext(AuthContext)
  const [calIsOpen, setCalIsOpen] = usePopUp(contRef, calRef)

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form }) || !find(tours, ['isActive', true])) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: location[lang].contactForm.onError,
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    const values = getFormValues({ form })

    setShowContactUs({
      show: true,
      title: 'Запрос предложения по турам',
      emailSubject: 'Запрос предложения по турма с сайта',
      additionalContent: `
       Дата тура: ${values.date}<br/>
       Выбранные экскурсии: ${tours
         .filter((t) => t.isActive)
         .map((t) => t.ru.title)
         .join(', ')}<br/>
       Кол-во туристов: ${values.tourists}
       `,
      target: 'konstr_tur',
    })
  }

  const setPickedDate = (date) => {
    const newDate = format(date, 'dd.LL.yyyy', { locale: ru })
    const clone = cloneDeep(form)
    clone.date.value = newDate
    clone.date.valid = true
    clone.date.touched = true
    setForm(clone)
    setCalIsOpen(false)
  }

  return (
    <section
      id="tour-constructor"
      className="TourConstructor DefaultSection"
      style={{ backgroundImage: `url(${constuctorBg})` }}
    >
      <div className="TourConstructor-AccentBackdrop" />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="TourConstructor-Container">
              <div className="Info">
                <SectionTitle
                  title={location[lang].tourConstructor.sectionTitle}
                  theme={width > 1200 ? 'light' : ''}
                />
                <h5 className="Title">
                  {location[lang].tourConstructor.title}
                </h5>
                <h5 className="Description">
                  {location[lang].tourConstructor.description}
                </h5>
              </div>
              <div className="Form">
                <FormStep
                  num="1"
                  title={location[lang].tourConstructor.form.step1}
                  content={
                    <>
                      <div ref={contRef}>
                        <FieldRender
                          key="date"
                          field={{ ...form.date, fieldId: 'date' }}
                          form={form}
                          setForm={setForm}
                          showErrors={showErrors}
                        />
                      </div>
                      {calIsOpen && (
                        <div ref={calRef}>
                          <DatePicker
                            disabledKeyboardNavigation
                            selected={
                              form.date.value.length === 10
                                ? convertDateFromString(form.date.value)
                                : null
                            }
                            onChange={setPickedDate}
                          />
                        </div>
                      )}
                    </>
                  }
                />
                <FormStep
                  num="2"
                  title={location[lang].tourConstructor.form.step2}
                  content={<TourSelector updateTours={setTours} />}
                />
                <FormStep
                  num="3"
                  title={location[lang].tourConstructor.form.step3}
                  content={
                    <FieldRender
                      key="tourists"
                      field={{ ...form.tourists, fieldId: 'tourists' }}
                      form={form}
                      setForm={setForm}
                      showErrors={showErrors}
                    />
                  }
                />
                {statusMessage.show && (
                  <StatusMessage
                    className="Site-StatusMessage"
                    type={statusMessage.type}
                    message={statusMessage.message}
                  />
                )}
                <div className="FormButton">
                  <Button
                    title={location[lang].tourConstructor.button}
                    fill="black"
                    theme="solid"
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function FormStep({ num, title, content }) {
  return (
    <div className="FormStep">
      <div className="Number">{num}</div>
      <div className="FormStep-Content">
        <p className="Title">{title}</p>
        <div>{content}</div>
      </div>
    </div>
  )
}

class TourConstructorForm {
  constructor() {
    this.date = {
      field: {
        fieldId: 'date',
        fieldType: 'input',
        inputType: 'text',
        icon: 'calendar-alt',
        iconPosition: 'left',
        mask: 'date',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    // this.tour = {
    //   field: {
    //     fieldId: 'tour',
    //     required: true,
    //   },
    //   render: getFieldRenderObject(),
    // }
    this.tourists = {
      field: {
        fieldId: 'tourists',
        fieldType: 'input',
        inputType: 'number',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

function TourSelector({ updateTours }) {
  const [excursions, setExcursions] = useState(null)
  const { lang } = useContext(AuthContext)

  const onSelect = (id) => {
    const clone = cloneDeep(excursions).map((e) => {
      if (e.tourId === id) {
        e.isActive = !e.isActive
      }
      return e
    })
    setExcursions(clone)
  }

  useEffect(() => {
    if (!isEmpty(excursions)) {
      updateTours(excursions)
    }
  }, [excursions])

  useEffect(() => {
    getCollection({
      path: 'tours',
      docIdName: 'tourId',
      whereQueries: [{ fieldPath: 'isPublished', op: '==', value: true }],
    }).then((data) => setExcursions(data))
  }, [])

  return (
    <div className="TourSelector">
      {!isEmpty(excursions)
        ? excursions.map((e) => (
            <div
              key={e.tourId}
              className={[
                'Tile',
                ...(e.isActive ? ['Tile_status_active'] : []),
              ].join(' ')}
              onClick={() => onSelect(e.tourId)}
            >
              <Icon name="check" weight="solid" />
              <span>{e[lang].title}</span>
            </div>
          ))
        : 'loading'}
    </div>
  )
}

export default TourConstructor
