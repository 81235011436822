import React, { useState, useContext } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Icon from '../../ui/Icon/Icon'
import './Feeds.sass'
import quote from './quote.svg'
// import { feedsArr } from './feedsArr'
import Carousel from '../../ui/Carousel/Carousel'
import smartTruncate from 'smart-truncate'
import PopUp from '../../ui/PopUp/PopUp'
import Button from '../../ui/Button/Button'
import location from '../../locale/location'
import { AuthContext } from '../../App'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty } from 'lodash'
import parse from 'html-react-parser'

function Feeds() {
  const [feedPopUp, setFeedPopUp] = useState({})
  const [glide, setGlide] = useState(null)
  const { lang } = useContext(AuthContext)
  const [feedsArr, setFeedsArr] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'feeds',
      docIdName: 'feedId',
      orderBy: { fieldPath: 'created', orderDir: 'desc' },
    }).then((result) =>
      setFeedsArr(
        result.map((f) => {
          const photoString = f.photoLinks
          const array = photoString
            .split('\n')
            .map((e) => {
              const str = e.split('"')[1]
              return str
            })
            .filter((e, i) => i !== 0)

          array.splice(-1)

          f.photoLinks = array

          return f
        })
      )
    )
  }, [])

  return (
    <section id="feeds" className="Feeds DefaultSection">
      <PopUp
        show={feedPopUp.show}
        close={() => setFeedPopUp({ show: false, title: '', text: '' })}
        title={feedPopUp.title}
        className="FeedPopUp"
      >
        <p>{feedPopUp.text ? parse(feedPopUp.text) : feedPopUp.text}</p>
        <div className="Buttons">
          <Button
            title={location[lang].feeds.close}
            fill="black"
            theme="solid"
            onClick={() => setFeedPopUp({ show: false, title: '', text: '' })}
          />
        </div>
      </PopUp>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title={location[lang].feeds.sectionTitle} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Feeds-Container">
              {!isEmpty(feedsArr) && (
                <Carousel
                  // element="FeedsArray"
                  options={{
                    type: 'carousel',
                    perView: 1,
                    startAt: 1,
                    autoplay: 5000,
                    useDots: false,
                    useArrows: false,
                  }}
                  getSlider={setGlide}
                >
                  {feedsArr.map((f) => (
                    <FeedTile
                      key={f.feedId}
                      {...f}
                      onReadMore={() =>
                        setFeedPopUp({
                          show: true,
                          title: f.author,
                          text: f.text,
                        })
                      }
                      slider={glide}
                    />
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function FeedTile({ author, photos, onReadMore, text, slider, photoLinks }) {
  const { lang } = useContext(AuthContext)
  // const userAvatar = find(photos, ['isAvatar', true])
  const userAvatar = photoLinks[0]
  return (
    <div className="FeedTile">
      <div className="User">
        <div className="Avatar">
          <img src={userAvatar} alt={author} />
        </div>
        <p className="UserName">{author}</p>
      </div>
      <div className="Feed">
        <div className="Feed-Short">
          <img src={quote} />
          <p>{parse(smartTruncate(text, 300))}</p>
        </div>
        <div className="Photos">
          {photoLinks.map((p) => (
            <a href={p} data-fancybox="images">
              <img src={p} className="FeedPhoto" />
            </a>
          ))}
        </div>
        <div className="Buttons">
          <span className="ReadMore" onClick={onReadMore}>
            {location[lang].feeds.readMore}
          </span>
          <div className="Arrows">
            <span className="Arrow" onClick={() => slider.go('<')}>
              <Icon name="angle-left" weight="regular" />
            </span>
            <span className="Arrow" onClick={() => slider.go('>')}>
              <Icon name="angle-right" weight="regular" />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feeds
