import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import './CartButton.sass'
import { AuthContext } from '../../App'

function CartButton() {
  const { cart } = useContext(AuthContext)
  const counter = cart.products.length
  return (
    <NavLink className={'Cart-Button'} to="/cart">
      {counter > 0 && <span className="Cart-Counter">{counter}</span>}
      <Icon name="shopping-bag" className="Cart-Icon" />
      <p>Корзина</p>
    </NavLink>
  )
}

export default CartButton
