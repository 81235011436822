export const ru = {
  links: {
    tours: 'Готовые туры',
    constructor: 'Конструктор тура',
    feeds: 'Отзывы',
    partners: 'Наши партнеры',
    contacts: 'Контакты',
  },
  hero: {
    title:
      'iTerra - отдых с душой<br/><span className="Bolder">на краю земли</span>',
    description:
      'Большая команда экспертов, высокий сервис,<br/> собственный транспорт, индивидуальный подход к каждому клиенту',
    tourButton: 'Выбрать готовый тур',
    constructorButton: 'Создать свой тур',
  },
  tourCatalog: {
    sectionTitle: 'Готовые туры',
    duration: {
      title: 'Продолжительность',
      hours: 'ч.',
      days: 'д.',
      day: 'День',
    },
    season: 'Сезон',
    hours: 'ч.',
    min: 'мин.',
    days: 'д.',
    price: 'Стоимость',
    program: 'Программа тура',
    from: 'от',
    currency: {
      title: 'руб',
      group: 'руб./чел. в cоставе группы',
      individual: 'руб. индивидуальная программа',
      person: 'руб./чел. за',
    },
    included: 'В стоимость включено',
    howToPrepare: 'Что брать с собой?',
    detailsButton: 'Подробнее о туре',
    bookTourButton: 'Забронировать тур',
  },

  tourConstructor: {
    sectionTitle: 'Конструктор туров',
    title: 'Создайте свой тур сами!',
    description:
      'Подберите желаемые параметры тура и наш менеджер свяжется с вами.',
    form: {
      step1: 'Когда начнем тур?',
      step2: 'Выберите экскурсии',
      step3: 'Укажите количество туристов',
    },
    button: 'Получить предложение',
  },

  feeds: {
    sectionTitle: 'Отзывы туристов',
    readMore: 'Читать далее',
    close: 'Закрыть',
  },
  faq: {
    sectionTitle: 'Часто задаваемые вопросы',
    buttonTitle: 'Задать другой вопрос',
  },
  partners: { sectionTitle: 'Наши партнёры' },
  contactForm: {
    onError: 'Пожалуйста, заполните все поля формы',
    onSuccess:
      'Спасибо за обращение. Наш менеджер свяжется с Вами в ближайшее время!',
    contactUs: 'Свяжитесь с нами',
    description: 'Мы ответим на все ваши вопросы в течение нескольких часов',
    sendForm: 'Отправить сообщение',
  },
}
