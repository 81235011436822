import React, { useEffect, useState } from 'react'
import Icon from '../../ui/Icon/Icon'
import Currency from '../Currency/Currency'
import './Checkout.sass'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import { createForm } from '../../utils/newforms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import FormRender from '../../utils/newforms/render/FormRender'
import Button from '../../ui/Button/Button'
import { NavLink, withRouter } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import { find, toNumber } from 'lodash'
import getCollection from '../../utils/db/getCollection'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import getFormValues from '../../utils/newforms/getFormValues'
import { functions } from '../../config/firebase'
import { updateDoc } from '../../utils/db/updateDoc'
import CheckTag from '../CheckTag/CheckTag'

function Checkout({ ...router }) {
  const [form, setForm] = useState(createForm({ formPattern: new OrderForm() }))
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const { cart } = useContext(AuthContext)
  const [totals, setTotals] = useState({
    counter: 0,
    fetchedProducts: [],
  })

  const [paymentType, setPaymentType] = useState(null)

  useEffect(() => {
    getCollection({ path: 'products', docIdName: 'productId' }).then((data) => {
      let counter = 0

      const fProducts = cart.products.map((p) => {
        const product = find(data, ['productId', p.id])
        counter += toNumber(product.price)

        return {
          ...p,
          ...product,
        }
      })
      setTotals({
        counter,
        fetchedProducts: fProducts,
      })
    })
  }, [cart.products])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      console.log('here')
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    const func = functions.httpsCallable('emailSender')

    func({
      subject: 'Новый заказ',
      to: 'diantus.roznica@mail.ru',
      html: `
      <b>Клиент</b><br/>
      Имя: ${values.name}<br/>
      Номер телефона: ${values.phone.value}<br/>
      Комментарий: ${values.comment}<br/><br/>
      <b>Доставка</b><br/>
      Дата доставки: ${values.delDate}<br/>
      Время доставки: ${values.delTime}<br/>
      Адрес: ${values.address}<br/>
      Имя получателя: ${values.persName}<br/><br/>
      <b>Оплата</b><br/>
      ${paymentType === 'cash' ? 'Наличные' : 'Онлайн оплата'}<br/><br/>
      <b>Заказ</b><br/>
    ${totals.fetchedProducts.map(
      (p) =>
        `${p.title} -- <span style="color: ${
          p.quantity > 1 ? 'red' : 'black'
        }">${p.quantity}</span> -- ${p.price * p.quantity} руб<br/>`
    )}
      `,
    }).then(({ data }) => {
      if (data === 'success') {
        updateDoc({
          path: 'carts',
          docId: cart.id,
          data: { products: [] },
        }).then(() => router.history.push('/success'))
      }
    })
  }

  return (
    <section className="InnerSection CheckOut">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="CheckOut-Container">
              <div className="CheckOut-Block">
                <h3 className="CategoryTitle">Оформление заказа</h3>
                {statusMessage.show && (
                  <StatusMessage
                    className="Site-StatusMessage"
                    type={statusMessage.type}
                    message={statusMessage.message}
                  />
                )}
                <div className="Steps">
                  <div className="Step1">
                    <div className="Step-Title">
                      <div className="StepIcon">1</div>
                      Контактная информация
                    </div>
                  </div>
                  <div className="Step2">
                    <div className="Step-Title">
                      <div className="StepIcon">2</div>
                      Доставка и оплата
                    </div>
                  </div>
                </div>
                <div className="CheckOut-Form">
                  <div className="LeftBlock">
                    <FormRender
                      form={form}
                      setForm={setForm}
                      sections={[{ fields: ['name', 'phone', 'comment'] }]}
                      errors={showErrors}
                    />
                  </div>
                  <div className="Step2">
                    <div className="Step-Title">
                      <div className="StepIcon">2</div>
                      Доставка и оплата
                    </div>
                  </div>
                  <div className="RightBlock">
                    <div className="TwoCols">
                      <FormRender
                        form={form}
                        setForm={setForm}
                        sections={[
                          {
                            fields: ['delDate', 'delTime'],
                          },
                        ]}
                        errors={showErrors}
                      />
                      <div className="AddressBlock">
                        <FormRender
                          form={form}
                          setForm={setForm}
                          sections={[
                            {
                              fields: ['address', 'persName'],
                            },
                          ]}
                          errors={showErrors}
                        />
                      </div>
                      <div className="PaymentTypes">
                        <p className="Title">Выберите способ оплаты</p>
                        <div className="Container">
                          <div
                            className={`PaymentMethod ${
                              paymentType === 'card'
                                ? 'PaymentMethod_theme_active'
                                : ''
                            }`}
                            onClick={() => setPaymentType('card')}
                          >
                            <CheckTag isActive={paymentType === 'card'} />
                            <Icon name="credit-card" weight={'solid'} />
                            <span className="Title">Онлайн оплата картой</span>
                          </div>
                          <div
                            className={`PaymentMethod ${
                              paymentType === 'cash'
                                ? 'PaymentMethod_theme_active'
                                : ''
                            }`}
                            onClick={() => setPaymentType('cash')}
                          >
                            <CheckTag isActive={paymentType === 'cash'} />
                            <Icon name="wallet" weight={'solid'} />
                            <span className="Title">
                              Наличными при получении
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="ConfirmButton">
                        <Button
                          title="Подтвердить заказ"
                          theme="solid"
                          fill="accent"
                          icon="angle-right"
                          iconPosition="right"
                          style={{ textTransform: 'uppercase' }}
                          onClick={onSubmit}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Cart-Totals">
                <h3 className="CategoryTitle">Ваш заказ</h3>
                <div className="Cart-TotalsBlock">
                  <div className="CheckOut-CartContainer">
                    <span className="CheckOut-CartIcon">
                      <Icon name="shopping-bag" />
                    </span>
                    <div className="CheckOut-CartDetails">
                      <div>
                        <p>Сумма заказа</p>
                        <NavLink to="/cart" className="CheckOut-ReturnLink">
                          <Icon name="edit" /> Перейти к корзине
                        </NavLink>
                      </div>
                      <span className="CheckOut-TotalSum">
                        {totals.counter} <Currency />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

class OrderForm {
  constructor() {
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ваше имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: 'Контактный телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.comment = {
      field: {
        fieldId: 'comment',
        fieldType: 'textarea-autosize',
        label:
          'Доп. комментарий. Это может быть текст для открытки (до 10 слов)',
      },
      render: getFieldRenderObject(),
    }
    this.delDate = {
      field: {
        fieldId: 'delDate',
        fieldType: 'input',
        label: 'Дата доставки',
        mask: 'date',
      },
      render: getFieldRenderObject(),
    }
    this.delTime = {
      field: {
        fieldId: 'delTime',
        fieldType: 'input',
        label: 'Время доставки',
        mask: 'time',
      },
      render: getFieldRenderObject(),
    }
    this.address = {
      field: {
        fieldId: 'address',
        fieldType: 'textarea',
        label: 'Адрес доставки',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.persName = {
      field: {
        fieldId: 'persName',
        fieldType: 'input',
        inputType: 'text',
        label: 'Имя получателя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.payCash = {
      field: {
        fieldId: 'payCash',
        fieldType: 'checkbox',
        label: 'Наличными',
      },
      render: getFieldRenderObject({ dropOnFieldIdValueChanged: 'payOnline' }),
    }
    this.payOnline = {
      field: {
        fieldId: 'payOnline',
        fieldType: 'checkbox',
        label: 'Онлайн оплата',
      },
      render: getFieldRenderObject({ dropOnFieldIdValueChanged: 'payCash' }),
    }
  }
}

export default withRouter(Checkout)
