import React, { useContext, useEffect, useRef, useState } from 'react'
import './Nav.sass'
import { NavLink, withRouter } from 'react-router-dom'
import getCollection from '../../utils/db/getCollection'
import { concat, isEmpty, orderBy, toNumber } from 'lodash'
import CartButton from '../CartButton/CartButton'
import promiseAllValues from '../../utils/promiseAllValues'
import Icon from '../../ui/Icon/Icon'
import toggleSpoiler from '../../utils/toggleSpoiler'
import Media from 'react-media'
import Hamburger from 'react-hamburger-menu'
import { usePopUp } from '../../hooks/usePopUp'
// import SiteNavLink from './SiteNavLink'
// import { usePopUp } from '../../hooks/usePopUp'
import useScrollPosition from '../../hooks/useScrollPosition'
import Logo from '../../ui/Logo/Logo'
// import { AuthContext } from '../../App'
// import PhoneChooser from './PhoneChooser/PhoneChooser'
// import location from '../../locale/location'

function Nav({ user, ...router }) {
  const [links, setLinks] = useState(null)
  const hamRef = useRef()
  const navRef = useRef()
  const [menuIsOpen, setMenuIsOpen] = usePopUp(hamRef, navRef)

  useEffect(() => {
    setMenuIsOpen(false)
  }, [router.location.pathname, router.location.hash])

  useEffect(() => {
    promiseAllValues({
      groups: getCollection({
        path: 'categoriesGroups',
        docIdName: 'id',
      }).then((data) => orderBy(data, ['pos'], ['asc'])),
      categories: getCollection({
        path: 'categories',
        docIdName: 'categoryId',
      }),
    }).then((data) => {
      const linksByGroupsArray = data.groups.map((g) => ({
        ...g,
        type: 'group',
        links: orderBy(
          data.categories
            .filter((c) => !c.isDeleted && c.group === g.id)
            .map((c) => {
              c.position = toNumber(c.position)
              return c
            }),
          ['position'],
          ['asc']
        ),
      }))

      const rawLinks = orderBy(
        data.categories
          .filter((d) => !d.isDeleted && !d.group && true)
          .map((d) => {
            d.position = toNumber(d.position)
            return d
          }),
        ['position'],
        ['asc']
      )
      setLinks(concat(linksByGroupsArray, rawLinks))
    })
  }, [])

  return (
    <section className="Nav">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Nav-Container">
              <Media
                query="(max-width: 1024px)"
                render={() => (
                  <>
                    <span ref={hamRef}>
                      <Hamburger
                        isOpen={menuIsOpen}
                        width={24}
                        height={16}
                        strokeWidth={2}
                        className="Site-Hamburger-Container"
                        color="#EF1A56"
                        borderRadius={2}
                        animationDuration={0.2}
                      />
                    </span>
                    <Logo navLink path={'/'} />
                  </>
                )}
              />
              <div
                ref={navRef}
                className="Nav-Menu"
                style={menuIsOpen ? { display: 'grid' } : {}}
              >
                {!isEmpty(links) &&
                  links.map((l) =>
                    l.type === 'group' ? (
                      <div className="GroupLink-Parent">
                        <div className="GroupLink" onClick={toggleSpoiler}>
                          <img
                            className="MenuIcon"
                            src={`/catIcons/${l.id}.svg`}
                            alt={l.title}
                          />
                          <span className="Menu-Link">{l.title}</span>
                          <Icon name="angle-down" weight={'regular'} />
                        </div>
                        <div className="GroupLink-Menu" onClick={toggleSpoiler}>
                          {l.links.map((cl) => (
                            <NavLink
                              to={`/products/${cl.categoryId}`}
                              className="Menu-Link ChildLink"
                            >
                              {cl.title}
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <NavLink
                        to={`/products/${l.categoryId}`}
                        className="Menu-Link"
                      >
                        <img src={l.photos[0].publicUrl} alt={l.title} />
                        {l.title}
                      </NavLink>
                    )
                  )}
              </div>
              <CartButton />
              <Media
                query="(max-width: 1024px)"
                render={() => (
                  <div className="TopContact">
                    <a href="tel: +7 (8152) 60-19-40" className="ContactIcon">
                      <Icon name="mobile" />
                    </a>
                    <div className="ContactPhone">
                      <a
                        className="Phone-Number"
                        href="tel: +7 (8152) 60-19-40"
                      >
                        +7 (8152) 60-19-40
                      </a>
                      <p className="Phone-Desc">Пн-Вс: с 09:00 до 20:00</p>
                    </div>
                  </div>
                )}
              ></Media>
            </div>
          </div>
        </div>
      </div>
    </section>
  )

  // const pos = useScrollPosition()
  // const { lang } = useContext(AuthContext)
  // useEffect(() => {
  //   setMenuIsOpen(false)
  // }, [router.location.pathname, router.location.hash])
  // return (
  //   <nav
  //     id="nav"
  //     className={[
  //       'Site-Nav',
  //       ...(pos > 0 ? ['Site-Nav_theme_shadow'] : []),
  //     ].join(' ')}
  //   >
  //     <Media
  //       query="(max-width: 1024px)"
  //       render={() => (
  //         <span ref={hamRef}>
  //           <Hamburger
  //             isOpen={menuIsOpen}
  //             width={24}
  //             height={16}
  //             strokeWidth={2}
  //             className="Site-Hamburger-Container"
  //             color="#303030"
  //             borderRadius={2}
  //             animationDuration={0.2}
  //           />
  //         </span>
  //       )}
  //     />
  //     <Logo theme="accent" navLink path="/#home" />
  //     <div
  //       ref={navRef}
  //       className="Site-Nav-Menu"
  //       style={menuIsOpen ? { display: 'grid' } : {}}
  //     >
  //       <SiteNavLink
  //         title={location[lang].links.tours}
  //         path="/#tours-catalog"
  //       />
  //       <SiteNavLink
  //         title={location[lang].links.constructor}
  //         path="#tour-constructor"
  //       />
  //       <SiteNavLink title={location[lang].links.feeds} path="#feeds" />
  //       <SiteNavLink title={location[lang].links.partners} path="#partners" />
  //       <SiteNavLink title={location[lang].links.contacts} path="#contacts" />
  //       <div className="Nav-Divider" />
  //       <PhoneChooser />
  //       {/* <LangChooser /> */}
  //     </div>
  //     <div className="MobileContacts">
  //       <PhoneChooser />
  //       {/* <LangChooser /> */}
  //     </div>
  //   </nav>
  // )
}

// const mapStateToProps = (state) => ({ user: state.auth.user })

export default withRouter(Nav)
