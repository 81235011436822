import React, { useEffect, useState } from 'react'
import './Categories.sass'
import { find, isArray, isEmpty, minBy, orderBy, toNumber } from 'lodash'
import getCollection from '../../utils/db/getCollection'
import { NavLink } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import promiseAllValues from '../../utils/promiseAllValues'

function Categories() {
  const [links, setLinks] = useState(null)

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'categories',
        docIdName: 'categoryId',
      }),
      products: getCollection({
        path: 'products',
        docIdName: 'id',
      }),
    }).then((data) => {
      const order = orderBy(
        data.categories
          .filter((d) => !d.isDeleted && true)
          .map((d) => {
            d.position = toNumber(d.position)
            return d
          }),
        ['position'],
        ['asd']
      )

      const catsWithPrices = order.map((c) => {
        const products = data.products
          .filter((p) => {
            if (isArray(p.category)) {
              return p.category.includes(c.categoryId)
            } else {
              return p.category === c.categoryId
            }
          })
          .map((p) => {
            p.price = toNumber(p.price)
            return p
          })
        c.products = products
        return c
      })

      setLinks(catsWithPrices)
    })
  }, [])

  return (
    <section className="Categories">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Categories-Container">
              {!isEmpty(links) &&
                links.map((l) => (
                  <>
                    <NavLink
                      to={`/products/${l.categoryId}`}
                      className="CategoryTile"
                      style={{
                        backgroundImage: `url(${
                          !isEmpty(l.photos)
                            ? find(l.photos, ['isAvatar', true]).publicUrl
                            : null
                        })`,
                      }}
                    >
                      <p>{l.title}</p>
                      {/* <div className="PriceLine">
                        <div className="PriceFrom">
                          от{' '}
                          <span className="Price">
                            {minBy(l.products, 'price').price}
                          </span>{' '}
                          ₽
                        </div>
                        <div className="Icon-Container">
                          <Icon name="angle-right" weight={'solid'} />
                        </div>
                      </div> */}
                    </NavLink>
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Categories
