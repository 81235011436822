import React, { useContext } from 'react'
import { AuthContext } from '../../../App'
import { find, isNull, toNumber } from 'lodash'
import Icon from '../../../ui/Icon/Icon'
import { clearProduct } from '../functions/clearProduct'
import Button from '../../../ui/Button/Button'
import { decreaseProductQuantity } from '../functions/decreaseProductQuantity'
import { increaseProductQuantity } from '../functions/increaseProductQuantity'
import Currency from '../../Currency/Currency'
import Spinner from '../../../ui/Spinner/Spinner'
import './MobileTable.sass'

function MobileTable({ fetchedProducts }) {
  const { cart } = useContext(AuthContext)

  return (
    <table className="CartTable">
      <thead>
        <tr>
          <th></th>
          <th>Товар</th>
        </tr>
      </thead>
      <tbody>
        {!isNull(fetchedProducts) ? (
          fetchedProducts.map((p) => {
            const img = find(p.photos, { isAvatar: true })
            return (
              <tr>
                <td className="CartTable-DeleteIcon">
                  <Icon
                    name="trash-alt"
                    onClick={() => clearProduct({ id: p.id, cart })}
                  />
                </td>
                <td className="MobileCell">
                  <div className="CartTable-ProductInfo">
                    <div className="Product-Avatar">
                      <img src={img ? img.publicUrl : null} alt={p.title} />
                    </div>
                    <div className="Product-Details">
                      <h4>{p.title}</h4>
                      <p>{p.description}</p>
                    </div>
                  </div>
                  <div className="CartTable-QuantityBlock">
                    <span className="BlockTitle">Количество:</span>
                    <Button
                      theme="circle"
                      fill="accent"
                      icon="minus"
                      // iconPosition="right"
                      style={{ textTransform: 'uppercase' }}
                      onClick={() =>
                        decreaseProductQuantity({ id: p.productId, cart })
                      }
                    />
                    <span className="QInput">{p.quantity}</span>
                    <Button
                      theme="circle"
                      fill="accent"
                      icon="plus"
                      // iconPosition="right"
                      style={{ textTransform: 'uppercase' }}
                      onClick={() =>
                        increaseProductQuantity({ id: p.productId, cart })
                      }
                    />
                  </div>
                  <div className="CartTable-ProdPrice">
                    <span className="BlockTitle">Цена:</span>
                    <span className="ProdSinglePrice">{p.price}</span>
                    <Currency />
                  </div>
                  <div className="CartTable-ProdPrice">
                    <span className="BlockTitle">Сумма:</span>
                    <span className="ProdTotalPrice">
                      {toNumber(p.price) * p.quantity}
                    </span>
                    <Currency />
                  </div>
                </td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td>
              <Spinner type="module" />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default MobileTable
