import { cloneDeep, find } from 'lodash'
import { updateDoc } from '../../../utils/db/updateDoc'

export const decreaseProductQuantity = ({ id, cart }) => {
  let newProducts = cloneDeep(cart.products)

  const productInCart = find(newProducts, ['id', id])
  if (productInCart) {
    if (productInCart.quantity > 1) {
      productInCart.quantity -= 1
    } else {
      newProducts = newProducts.filter((p) => p.id !== id)
    }
  }

  console.log('🚀 ~ decreaseProductQuantity ~ newProducts:', newProducts)
  return updateDoc({
    path: 'carts',
    docId: cart.id,
    data: { products: newProducts },
  })
}
